import { dateToYMD } from 'helpers'
import { FilterOperator, ObjectParameters } from './types'

const buildFetchParameters = ({
  params, objectDate, sortedField, filterList,
} : ObjectParameters) => {
  const filters = filterList.map(filter => ({
    column: filter.column.rawName,
    operator: FilterOperator[filter.operator as keyof typeof FilterOperator],
    value: filter.value,
  }))
  const date = objectDate ? dateToYMD(objectDate) : undefined
  const sort = sortedField.column ? { ...sortedField, order: sortedField.order?.toUpperCase() } : undefined
  return {
    tree: { ...params, join: [] }, date, sort, filters,
  }
}

export default buildFetchParameters
