import { lazy, LazyExoticComponent, ReactElement } from 'react'
import userIcon from 'assets/icons/user.svg'

const Home = lazy(() => import('../Home'))

export const ACCESS_PERM = 'gaia-viz::ACCESS'

export type NavElement = {
  icon: string;
  title: string;
  path: string;
  component: LazyExoticComponent<() => ReactElement>;
  role: Array<string>;
}

const navRoutes: NavElement[] = [
  {
    icon: userIcon,
    title: '',
    path: '',
    component: Home,
    role: [ACCESS_PERM],
  },
]

export default navRoutes
