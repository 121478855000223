const EMPTY_RESULTS = { perfectMatchCount: 0, types: [] }
const EMPTY_OBJECTS = {
  columns: [],
  rows: {
    count: 0, next: '', previous: '', results: [],
  },
}

const EMPTY_SORT = {
  column: '', order: undefined,
}

export { EMPTY_RESULTS, EMPTY_OBJECTS, EMPTY_SORT }
