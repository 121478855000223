import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import DidymeServices from 'services/DidymeServices'
import {
  Filter,
  ObjectResults, ObjectType, SearchResults, Sort,
} from 'services/DidymeServices/types'
import { EMPTY_OBJECTS, EMPTY_RESULTS, EMPTY_SORT } from './utils'

interface DidymeState {
  searchResults: SearchResults;
  searchType: string | ObjectType;
  searchDate: string;
  searchLoading: boolean;
  objectsList: ObjectResults;
  fetchLoading: boolean;
  sortedField: Sort;
  filters: Filter[];
  filterMenuOpen: boolean;
}

const initialState: DidymeState = {
  searchResults: EMPTY_RESULTS,
  searchType: '',
  searchDate: new Date().toDateString(),
  searchLoading: false,
  objectsList: EMPTY_OBJECTS,
  fetchLoading: false,
  sortedField: EMPTY_SORT,
  filters: [],
  filterMenuOpen: false,
}

export const didymeSlice = createSlice({
  name: 'didyme',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string | ObjectType>) => {
      state.searchType = action.payload
    },
    setSearchDate: (state, action: PayloadAction<string>) => {
      state.searchDate = action.payload
    },
    resetSearch: state => {
      state.searchResults = EMPTY_RESULTS
      state.objectsList = EMPTY_OBJECTS
      state.searchType = ''
      state.sortedField = EMPTY_SORT
      state.filters = []
      state.filterMenuOpen = false
    },
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sortedField = action.payload
    },
    showFilterMenu: (state, action: PayloadAction<boolean>) => {
      state.filterMenuOpen = action.payload
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(DidymeServices.searchObjects.pending, state => {
      state.searchLoading = true
    })
    builder.addCase(DidymeServices.searchObjects.fulfilled, (state, { payload }) => {
      state.searchResults = payload
      state.searchLoading = false
    })
    builder.addCase(DidymeServices.searchObjects.rejected, state => {
      state.searchLoading = false
    })
    builder.addCase(DidymeServices.fetchObjects.pending, state => {
      state.fetchLoading = true
    })
    builder.addCase(DidymeServices.fetchObjects.fulfilled, (state, { payload }) => {
      state.objectsList = payload
      state.fetchLoading = false
    })
    builder.addCase(DidymeServices.fetchObjects.rejected, state => {
      state.fetchLoading = false
    })
  },
})

export const {
  setSearch, setSearchDate, resetSearch, setSort, showFilterMenu, setFilters,
} = didymeSlice.actions

export default didymeSlice.reducer
