/* eslint-disable camelcase */
// TODO  better type naming

import { UnknownObject } from 'types'

type ObjectField = {
  slug: string;
  description: string;
  type: string;
  unbounded: boolean;
}

type Link = {
  field: string;
  kind: 'forward' | 'reverse'
  completenesse: number;
}

type Join = {
  link: Link[];
  tree: UnknownObject
}

type Tree = {
  type: string;
  schema: string;
  fields: ObjectField[];
  join: Join[];
}
export type ObjectType = {
  type: string;
  schema: string;
  description: string;
  matchedField: string | null;
  count: number;
  fields: ObjectField[];
  tree: Tree[];
}

export type SearchResults = {
  perfectMatchCount: number;
  types: ObjectType[];
}

export type SearchParameters = {
  q: string;
  from_schema?: string;
  from_type?: string;
  limit?: number;
  schemas?: string;
}

export enum ObjectSchemas {
  Gaia = 'gaia',
  Basic = 'basic',
}

type ColumnType = 'long' | 'double' | 'int' | 'string' | 'uuid' | 'list' | 'dateTime' | 'boolean'

export const columnNumberTypes = ['long', 'double', 'int', 'list', 'dateTime']

export const columnStringTypes = ['string', 'uuid']

export type Column = {
  displayedName: string;
  rawName: string;
  subType: string;
  type: string;
  unbounded: boolean;
  columnType: ColumnType
}

export type Row = string[]

type Result = {
  count: number;
  next: string;
  previous: string;
  results: Row[];
}

export type ObjectResults = {
  columns: Column[];
  rows: Result;
}

export type ObjectParameters = {
  params: ObjectType;
  page: number;
  objectDate: string;
  sortedField: Sort;
  filterList: Filter[]
}

export type Order = 'asc' | 'desc' | undefined

export type Sort = {
  column: string;
  order: Order;
}

export enum StringFilterOperator {
  equals = 'EQ',
  notEquals = 'NEQ',
  contains = 'CONTAINS',
  notContains = 'NOT_CONTAINS',
  startsWith = 'STARTS_WITH',
  endsWith = 'ENDS_WITH',
}

export enum NumberDateFilterOperator {
  equals = 'EQ',
  notEquals = 'NEQ',
  greaterThan = 'GTE',
  strictGreaterThan = 'GT',
  lowerThan = 'LTE',
  strictLowerThan = 'LT',
}

export enum BooleanFilterOperator {
  equals = 'EQ',
  notEquals = 'NEQ',
}

export const FilterOperator = { ...StringFilterOperator, ...NumberDateFilterOperator, ...BooleanFilterOperator }

export type FilterOperatorType = StringFilterOperator | NumberDateFilterOperator | BooleanFilterOperator

export type Filter = {
  id: number;
  column: Column;
  operator: FilterOperatorType | string;
  value: string;
}
