import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'
import { ObjectParameters, SearchParameters } from './types'
import buildFetchParameters from './utils'

const searchObjects = createAsyncThunk(
  'didyme/searchObjects',
  async (params: SearchParameters, thunkApi) => {
    try {
      const response = await get(`/${URI.didyme}/search`, params)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchObjects = createAsyncThunk(
  'didyme/fetchObjects',
  async ({
    params, objectDate, page, sortedField, filterList,
  } : ObjectParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchParameters({
        params, objectDate, page, sortedField, filterList,
      })
      const response = await post(`/${URI.didyme}/fetch/`, { ...fetchParameters }, { params: { page } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const DidymeServices = {
  searchObjects, fetchObjects,
}

export default DidymeServices
